<!--========================
Author by: Tith Vannarith
Created on: 03.Sept.2021
Modified:
Company: Soteca
Description:
=========================-->
<template>
  <div>
    <v-card class="ma-4" flat>
      <TitleBar>
        Flows
        <template #right-items>
          <PrimaryButton @click="$router.push('/flow/create')">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>
      <v-data-table :headers="headers" :items="flows" :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.active="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.open="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.readonly="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <!-- <v-icon small class="mr-4" @click="viewFlow(item.idform)">
            mdi-format-list-bulleted
          </v-icon> -->
          <v-icon small class="mr-4" @click="editFlow(item.idform)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="confirmDelete(item.idform)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="400px" v-model="showHideModal">
      <v-card>
        <v-card-title>Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <dismiss-button @click="showHideModal = false">Discard</dismiss-button>
          <primary-button @click="deleteFlow" color="warning">OK</primary-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "HomeFlow",

  data: function () {
    return {
      search: "",
      flows: [],
      showHideModal: false,
      flowIdToDelete: null,
      headers: [
        { value: "name", text: "Name" },
        { value: "readonly", text: "Readonly", width: "120px" },
        { value: "open", text: "Open", width: "120px" },
        { value: "active", text: "Active", width: "120px" },
        {
          value: "actions",
          text: "Action",
          width: "120px",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  mounted() {
    this.getFlows();
  },
  methods: {
    async getFlows() {
      this.$store.commit("toggle", "showHideModalSpinner");
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      };

      try {
        const response = await this.$http.get("/api/form/with_flows", { headers });
        this.flows = response.data;
        // console.log(this.flows)
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    editFlow(idform) {
      this.$router.push("/flow/edit/" + idform);
    },
    confirmDelete(idform) {
      this.showHideModal = true;
      this.flowIdToDelete = idform;
    },
    async deleteFlow() {
      this.showHideModal = false;
      this.$store.commit("set", ["modalSpinnerText", "Deleting..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };

      try {
        await this.$http.delete("/api/step/" + this.flowIdToDelete, {
          headers,
        });
        this.getFlows();
        //this.$store.commit("toggle", "showHideModalSpinner");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
  },
};
</script>
