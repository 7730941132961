var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('TitleBar',{scopedSlots:_vm._u([{key:"right-items",fn:function(){return [_c('PrimaryButton',{on:{"click":function($event){return _vm.$router.push('/flow/create')}}},[_vm._v("Add New")])]},proxy:true}])},[_vm._v(" Flows ")]),_c('SectionBar',{scopedSlots:_vm._u([{key:"left-items",fn:function(){return [_c('SearchTextField',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.flows,"search":_vm.search},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.open",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.readonly",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.editFlow(item.idform)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDelete(item.idform)}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.showHideModal),callback:function ($$v) {_vm.showHideModal=$$v},expression:"showHideModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Are you sure you want to delete?")]),_c('v-card-actions',[_c('v-spacer'),_c('dismiss-button',{on:{"click":function($event){_vm.showHideModal = false}}},[_vm._v("Discard")]),_c('primary-button',{attrs:{"color":"warning"},on:{"click":_vm.deleteFlow}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }